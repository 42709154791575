






  import { Component, Vue } from "vue-property-decorator";
import QuienesSomos from "@/components/MedioAmbiente/QuienesSomos.vue";
  
  @Component({
    components: {
      QuienesSomos
    },
    meta: {
      // sets document title
      title: "Medio Ambiente | Quiénes Somos - Municipalidad de Arica",
      // optional; sets final title as "Index Page - My Website", useful for multiple level meta
      //titleTemplate: title => `${title} - My Website`,
  
      // meta tags
      meta: {
        description: {
          name: "medio ambiente",
          content: "Medio ambiente."
        },
        keywords: { name: "keywords", content: "Arica" },
        equiv: {
          "http-equiv": "Content-Type",
          content: "text/html; charset=UTF-8"
        }
      }
    }
  })
  export default class QuienesSomosView extends Vue {
    private mounted() {
      document.title = "Medio Ambiente | Quiénes Somos - Municipalidad de Arica";
      var canonicalLink = document.createElement("link");
      // Establece los atributos del enlace
  
      canonicalLink.rel = "canonical";
      canonicalLink.href = "https://muniarica.cl/medio-ambiente/quienes-somos";
      document.head.appendChild(canonicalLink);
    }
  }
  