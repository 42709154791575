




import { Component, Vue } from "vue-property-decorator";
import QueHacemos from "@/components/MedioAmbiente/QueHacemos.vue";

@Component({
  components: {
    QueHacemos,
  },
  meta: {
    // sets document title
    title: "Medio Ambiente | Qué Hecemos - Municipalidad de Arica",
    // optional; sets final title as "Index Page - My Website", useful for multiple level meta
    //titleTemplate: title => `${title} - My Website`,

    // meta tags
    meta: {
      description: {
        name: "medio ambiente",
        content: "Medio ambiente.",
      },
      keywords: { name: "keywords", content: "Arica" },
      equiv: {
        "http-equiv": "Content-Type",
        content: "text/html; charset=UTF-8",
      },
    },
  },
})
export default class QueHacemosView extends Vue {
  private mounted() {
    document.title = "Medio Ambiente | Qué Hecemos - Municipalidad de Arica";
    var canonicalLink = document.createElement("link");
    // Establece los atributos del enlace

    canonicalLink.rel = "canonical";
    canonicalLink.href = "https://muniarica.cl/medio-ambiente/que-hacemos";
    document.head.appendChild(canonicalLink);
  }
}
