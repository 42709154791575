



















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import router from "@/router";
import { Component, Vue, Watch } from "vue-property-decorator";

interface dataBanner {
  id: number;
  imagen: string;
  imagen_mobile: string;
  link: string;
}

@Component({})
export default class MedioAmbiente extends Vue {
  private slide: any = 1;
  private autoplay: any = true;
  private BannerUno = require("../../assets/logos/logo800.webp");
  private slideIndex: number = 1;
  private aBanners: Array<dataBanner> = [];
  private dialogDenuncias: any = false;
  private dialogHumedal: any = false;
  private dialogEducacion: any = false;
  private dialogGolondrina: any = false;
  private dialogPac: any = false;
  private dialogScam: any = false;
  private dialogResiduos: any = false;
  private dialogCambio: any = false;
  private dialogEvaluaciones: any = false;
  private menuLeft: any = false;
  private activeItem: any = "";

  private cards = [
    { imgSrc: require("@/assets/medioambiente/humedal.png"), label: "Humedal" },
    {
      imgSrc: require("@/assets/medioambiente/educacion.png"),
      label: "Educación Ambiental",
    },
    {
      imgSrc: require("@/assets/medioambiente/golondrina.png"),
      label: "Golondrina de mar",
    },
    { imgSrc: require("@/assets/medioambiente/pac.png"), label: "PAC" },
    { imgSrc: require("@/assets/medioambiente/scam.png"), label: "SCAM" },
    {
      imgSrc: require("@/assets/medioambiente/veterinaria.png"),
      label: "Veterinaria",
    },
    {
      imgSrc: require("@/assets/medioambiente/tenencia.png"),
      label: "Tenencia Responsable",
    },
    {
      imgSrc: require("@/assets/medioambiente/residuos.png"),
      label: "Residuos y Economía Circular",
    },
    {
      imgSrc: require("@/assets/medioambiente/cambio-climatico.png"),
      label: "Cambio Climático",
    },
    {
      imgSrc: require("@/assets/medioambiente/evaluacion.png"),
      label: "Evaluación Ambiental de Proyectos",
    },
  ];

  @Watch("$route", { immediate: true, deep: true })
  private changeRoute(newValue: any) {
    if (this.$route && this.$route.name) {
      console.log(this.$route);
      if (this.$route.name == "medio-ambiente") {
        this.activeItem = "medio-ambiente";
      }
    } else {
      console.log("Route or route name is undefined");
    }
  }
  private created() {
    this.aBanners.push(
      {
        id: 1,
        imagen: this.BannerUno,
        imagen_mobile: "",
        link: "",
      },
      {
        id: 2,
        imagen: require("@/assets/medioambiente/banner-uno.png"),
        imagen_mobile: "",
        link: "",
      }
    );
  }

  private currentSlide(n) {
    this.showSlides((this.slideIndex = n));
  }

  private showSlides(n) {
    const slides = document.getElementsByClassName("mySlides");
    const dots = document.getElementsByClassName("dot");
    if (n > slides.length) this.slideIndex = 1;
    if (n < 1) this.slideIndex = slides.length;

    for (let i = 0; i < slides.length; i++) {
      (slides[i] as HTMLElement).style.display = "none";
    }
    for (let i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace(" active", "");
    }
    (slides[this.slideIndex - 1] as HTMLElement).style.display = "block";
    dots[this.slideIndex - 1].className += " active";
  }

  private toLink(item: string, target: string) {
    if (item) {
      window.open(item, target);
    }
  }

  private openDialog(val: any) {
    if (val == "dialogDenuncias") {
      this.dialogDenuncias = true;
    } else if (val == "Humedal") {
      this.dialogHumedal = true;
    } else if (val == "Educación Ambiental") {
      this.dialogEducacion = true;
    } else if (val == "Golondrina de mar") {
      this.dialogGolondrina = true;
    } else if (val == "PAC") {
      this.dialogPac = true;
    } else if (val == "SCAM") {
      this.dialogScam = true;
    } else if (val == "Residuos y Economía Circular") {
      this.dialogResiduos = true;
    } else if (val == "Cambio Climático") {
      this.dialogCambio = true;
    } else if (val == "Evaluación Ambiental de Proyectos") {
      this.dialogEvaluaciones = true;
    } else if (val == "Veterinaria") {
      this.toLink("https://imaarica.cl/veterinaria/", "blank");
    }
  }

  private routerGo(name: any) {
    if (this.$router.currentRoute.name == name) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: name,
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  private enviarEmail(email) {
    let mailtoLink = "mailto:" + email;
    window.location.href = mailtoLink;
  }
  private llamar(data: any) {
    const numero = data;
    window.open("tel:" + numero);
  }
}
